<template>
  <b-modal
      size="lg"
      body-class="p-0"
      title-class="text-primary"
      hide-footer
      id="modal-esim"
      centered
      hide-header
  >
    <div class="header d-flex justify-content-between">
      <h5 class="">Các thiết bị di động có hỗ trợ eSIM</h5>
      <button @click=" closeModal()" class="content-esim__closeButton"><img src="@/assets/images/close-icon.png">
      </button>
    </div>
    <div class="content-esim ">
      <div class="content-esim__title" @click="isShowDeviceSearch = false">
        Lưu ý: eSIM là một loại SIM điện tử (sử dụng mã QR) và KHÔNG PHẢI thẻ SIM vật lý lắp vào điện thoại. eSIM không
        dùng cho các đồng hồ thông minh
      </div>
      <h5>
        Tra cứu dòng máy của bạn tại đây!
      </h5>
      <div style="position: relative">
        <b-form-input ref="txtSearch" @update="searchDevice()" @click="isShowDeviceSearch = true"
                      class="font-medium-4" autocomplete="nope"
                      v-model="device" style="border-radius: 0px !important; position: sticky"
                      id="searchright"
        />
        <p v-if="device.length<=0" class="search-device-placeholder" @click="()=>{$refs.txtSearch.focus();isShowDeviceSearch = true}">Nhập tên máy <span
            class="font-italic">(ví dụ iphone 15, Samsung Galaxy Fold…)</span></p>
      </div>
      <ul class="ul-device-search ul-device-search-result" v-if="isShowDeviceSearch">
        <li v-for="(p, stt) in searchDeviceResult"
            class="search-device-result" :key="stt" :title="p.Name"
            :style="p.Code===0?'color:red':''">
          {{ p.Name }}
        </li>
      </ul>
      <div class="content-esim__table table-responsive-sm mt-2" @click="isShowDeviceSearch = false">
        <table class="table table-bordered table">
          <thead>
          <tr>
            <th class="rademark title-table">Thiết bị</th>
            <th class="title-table">Dòng máy</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>Apple</th>
            <td>
              - iPhone XR (mẫu A2105, từ 2018) <br/>
              - iPhone XS (mẫu A2097, từ 2018) <br/>
              - iPhone XS Max (mẫu A2101, từ2018) <br/>
              - iPhone 11 (mẫu A2221, từ 2019) <br/>
              - iPhone 11 Pro (mẫu A2215, từ 2019) <br/>
              - iPhone 11 Pro Max <br/>
              - iPhone SE (mẫul 2020) <br/>
              - iPhone 12/ 12mini/ 12 Pro/ 12 Pro Max <br/>
              - iPhone 13/ 13 mini/ 13 Pro/ 13 Pro Max <br/>
              - iPhone 14/ 14 Plus/ 14 Pro/ 14 Pro Max <br/>
              - iPhone 15/ 15 Plus/ 15 Pro/ 15 Pro Max <br/>
              <span class="fs-error-txt">
                     LƯU Ý: Đối với các dòng máy Lock không hỗ trợ eSIM.
                  </span>
            </td>
          </tr>
          <tr>
            <th scope="row">Google</th>
            <td colspan="2">
              - Google Pixel 7/ 7 Pro <br>
              - Google Pixel 6/ 6a/ 6 Pro <br>
              - Google Pixel 5/ 5a 5G <br>
              - Google Pixel 4/ 4a/ 4a 5G/ 4 XL <br>
              - Google Pixel 3/ 3a/ 3a XL/ 3 XL
            </td>
          </tr>
          <tr>
            <th>Huawei</th>
            <td colspan="2">
              - Huawei P40/ P40 4G/ P40 Pro <br>
              - Huawei Mate 40 Pro
            </td>
          </tr>
          <tr>
            <th>Oppo</th>
            <td colspan="2">
              - Oppo Reno 5 A/ 6 Pro 5G <br>
              - Oppo Find X3/ X3 Pro/ X5/ X5 Pro
            </td>
          </tr>
          <tr>
            <th>Samsung</th>
            <td>
              - Samsung Galaxy Fold <br>
              - Samsung Galaxy Note 20 /Note 20 Ultra <br>
              - Samsung Galaxy S20/ S20+ / S20 Ultra <br>
              - Samsung Galaxy S21 5G / S21+ 5G/ S21 Ultra 5G <br>
              - Samsung Galaxy S22 5G/ S22 Plus 5G/ S22 Ultra <br>
              - Samsung Galaxy S23 / S23 Plus / S23 Ultra <br>
              - Samsung Galaxy Z Flip/ Flip3 5G /Flip 4/ Z Flip5 <br>
              - Samsung Galaxy Z Fold 2/ Fold3 5G/ Fold 4/ Fold 5
            </td>
          </tr>
          <tr>
            <th>Sony</th>
            <td colspan="2">
              - Sony Xperia 10 III Lite/ 5 IV/ 1 IV
            </td>
          </tr>
          <tr>
            <th>iPad</th>
            <td>
              - iPad Pro LTE (2018) <br>
              - iPad Pro 11″ (mẫu A2068, từ 2020)/ iPad Pro 11 (2021, 2020) <br>
              - iPad Pro 12.9″ (mẫu A2069, từ 2020)/ iPad Pro 12.9 (2021, 2020, 2017, 2015) / Apple iPad Pro 12.9 (2021,
              2018) <br>
              - iPad Air (mẫu A2123, từ 2019)/ iPad Air (2022, 2020) <br>
              - iPad (mẫu A2198, từ 2019) <br>
              - iPad Mini (mẫu A2124, từ 2019)/ iPad mini (2021, 2019)/ iPad mini 3<br>
              - iPad 10.2 (2021, 2020, 2019) <br>
              - iPad 9.7 (2016)
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="footer">
        <div class="w-100 pt-1 text-center">
          <b-button @click=" closeModal()" variant="primary" class="">Xác nhận</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {
  BButton, BFormInput,
} from "bootstrap-vue";
import Select2 from "v-select2-component";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import Vue from "vue";

Vue.component("v-select", vSelect);
export default {
  components: {
    BFormInput,
    BButton,
    Select2,
  },
  name: "ModalDetaiEsim",
  props: {
    isShowEsim: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowDeviceSearch: false,
      device: '',
      supportDevice: [],
      searchDeviceResult: [],
    };
  },
  mounted() {
    this.getDevice();
  },
  watch: {
    isShowEsim() {
      this.$bvModal.show("modal-esim");
    },

  },
  methods: {
    closeModal() {
      this.isShowDeviceSearch = false;
      this.$bvModal.hide("modal-esim");
    },
    getDevice() {
      useJwt.get('device-support-esim').then((res) => {
        this.supportDevice = res.data.result
        this.searchDeviceResult = res.data.result
      }).catch((err) => {
        console.log("check err", err);
      })
    },
    searchDevice() {
      this.searchDeviceResult = this.supportDevice.filter((item) => {
        return item.Name.toLowerCase().includes(this.device.toLowerCase());
      });
      if (this.searchDeviceResult.length === 0) {
        this.searchDeviceResult = [{Code: 0, Name: "Dòng máy của Quý Khách chưa được hỗ trợ"}]
      }
    },
  }

};
</script>
<style scoped scoped lang="scss">
.ul-device-search {
  width: 95%;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: inline-block;
  position: absolute;
  top: 26%;
  background-color: #fff;
  z-index: 999;
  border: 1px solid #e0e0e0;
}

.ul-device-search-result {
  max-height: 250px;
  overflow-y: auto;
}

.search-device-result {
  font-size: 16px;
  padding: 1rem;
}

#modal-esim .modal-content {
  width: 800 !important;
  border-radius: 10px;
}

.header {
  background-color: #f3f2f7;
  border-radius: 10px;

  h5 {
    color: #424242;
    font-weight: 600;
    font-size: 1.6rem;
    padding: 15px;
  }
}

.content-esim {
  padding: 1.4rem;

  &__title {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: red;
    margin-bottom: 1.4rem;
  }

  &__closeButton {
    margin-right: 1.5rem;
  }

  &__table {
    max-height: 50rem;
    overflow: scroll;

    table {
      font-size: 1.4rem;

      thead {
        th {
          color: #424242;
          text-align: center;
          font-weight: 900;
          padding: 0.5rem 1rem;
        }
      }

      .rademark {
        width: 100px !important;
      }

      .title-table {
        font-size: 1.4rem;
      }

      td {
        line-height: 2rem;
      }
    }
  }
}

.footer {
  bottom {
    font-size: 1.4rem;
  }
}

.search-device-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.5rem;
  font-size: 1.4rem;
  color: #a9a9a9;
}
@media screen and (max-width: 400px) {
  .search-device-placeholder {
    padding: 1rem;
    font-size: 1.3rem;
  }
  .ul-device-search{
    top: 28%;
    width: 92%;
  }
}
@media screen and (max-width: 450px) {
  .search-device-placeholder {
    font-size: 1.3rem;
  }
  .ul-device-search{
    top: 28%;
    width: 92%;
  }
}
</style>
