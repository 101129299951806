<template>
  <div class="lucky_number mt-3l">
    <div class="lucky_number__item1">
      <h5 class="title">Số may mắn được chọn của bạn là</h5>

      <div class="d-flex number-select">
        <span class="phone" v-html="dataLuckyNumber.PhoneFormated">
        </span>
        <button class="rotation-btn" @click="handleGetLuckyNumber">
          <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="isLoading ? 'loading' : ''"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.36292 9.96427C1.6835 9.96427 1.95064 10.249 1.95064 10.5338C1.95064 13.8083 4.9427 16.4184 8.5759 16.4184C12.316 16.4184 15.308 13.7608 15.308 10.5338C15.308 7.54395 12.8503 5.12364 9.64449 4.64907V7.63887L5.20984 4.22195L9.64449 0.757578V3.55755C13.5983 3.93721 16.5903 6.92701 16.5903 10.5812C16.5903 14.4727 13.064 17.6049 8.62933 17.6049C4.24811 17.6049 0.668335 14.4727 0.668335 10.5812C0.668335 10.249 0.882053 9.96427 1.36292 9.96427Z"
              fill="#616161"
            />
          </svg>
        </button>
        <span class="text-not">(Chọn số khác)</span>
      </div>
      <hr class="hr" />
    </div>
    <div class="lucky_number__item2">
      <h5 class="title">Gói cước</h5>
      <div class="d-flex number-select">
        <span class="package_information"
          >Gói
          <strong
            >{{ dataLuckyNumber.Pack }}
            <span style="font-size: 16px; color: #ed1f24;">
              {{ dataLuckyNumber.PackPrice | number }}đ
              <span class="b2c-ghtk-fee" style="color: #5e5873; font-weight: 400;" v-if="dataLuckyNumber.PackPrice !=packPrice && dataLuckyNumber.SimType=== 10">
              ({{ packPrice | number }}đ)
              </span>
            </span>
            </strong
          >
          <br />
          <span>
            <strong>4GB/ngày</strong> , miễn phí gọi nội mạng Vinaphone &
            iTel</span
          >
          <br />
          <p style="font-style: italic; font-size: 12px;">
            (Ưu đãi giá gói áp dụng cho tháng đầu tiên)
          </p>
        </span>
      </div>
    </div>
    <div class="lucky_number__item3 d-flex align-items-center">
      <div class="d-flex flex-column w-120">
        <span class="price_title">Giá tiền</span>
        <span class="price"
          >{{
            (dataLuckyNumber.Price + dataLuckyNumber.SimPrice) | number
          }}đ</span
        >
        <span class="b2c-ghtk-fee b2c-price-texts" v-if="(dataLuckyNumber.Price + dataLuckyNumber.SimPrice) !=50000
        && dataLuckyNumber.SimType=== 10" style="font-size: 18px; color: #5e5873; font-weight: 300;">50.000đ</span>
      </div>
      <button :disabled="isDisabled" class="buy_now" :class="isDisabled ? 'disablad' : ''" @click="hadleByNow(dataLuckyNumber)">
        Mua ngay
      </button>
    </div>
    <img
      class="lucky_number__img"
      loading="lazy"
      src="@/assets/images/b2c/mua-hang.png"
      alt="Itel"
    />
  </div>
</template>
<script>
import luckyNumber from "@/mixins/luckyNumber.js";

export default {
  mixins: [luckyNumber],
  props: ['DataPackages'],
  computed:{
    packPrice(){
      if(this.DataPackages.find((m) => m.Name == this.dataLuckyNumber.Pack)){
        return this.DataPackages.find((m) => m.Name == this.dataLuckyNumber.Pack).Price
      }
    },
  }
};
</script>
<style scoped lang="scss">
.lucky_number {
  gap: 7rem;
  display: flex;
  margin: 0 auto;
  margin-top: 32px;
  position: relative;
  max-width: 122.4rem;
  border: 28px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
  padding: 20px 28px;
  &__img {
    position: absolute;
    right: 0;
    top: -32px;
    right: 20px;
  }
  &__item1 {
    position: relative;
    .title {
      color: #3d3d3d;
      font-size: 17px !important;
      font-weight: 700;
      margin-bottom: 15px;
      margin-top: 14px;
    }
    .hr {
      position: absolute;
      width: 84px;
      // left: 50%;
      transform: translate(-50%);
      height: 2.5px;
      background: #e9182c;
      transform: rotate(90deg);
      top: 37px !important;
      right: -77px;
    }
  }
  &__item3 {
    .w-120 {
      width: 120px;
    }
    .price_title {
      font-weight: 700;
      font-size: 18px;
      color: #3d3d3d;
      margin-bottom: 12px;
    }
    .price {
      color: #e9182c;
      font-size: 27px;
      font-weight: 800;
    }
    .buy_now {
      margin-left: 32px;
      width: 156px;
      height: 46px;
      background: #ed1f24;
      border: 2px solid #ed1f24;
      border-radius: 26.8042px;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
    }
    .disablad {
      opacity: 0.8;
    }
  }
  &__item2 {
    .title {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 18px !important;
    }
    .package_information {
      font-size: 15px;
      color: #3d3d3d;
      line-height: 20px;
      span {
        font-size: 12px;
      }
    }
  }
  .number-select {
    gap: 11px;
    align-items: center;
  }
  title {
    font-weight: 700 !important;
    font-size: 17.3333px !important;
    line-height: 14px;
    /* or 81% */
    color: #3d3d3d !important;
  }
  .phone {
    color: #e9182c;
    font-size: 24px;
    font-weight: 700;
    transition: all 0.5s ease;
  }
  .rotation-btn {
    width: 33px;
    height: 31px;
    background: #ffffff;
    border: 1.63975px solid #616161;
    box-shadow: 2.18634px 2.18634px 6.55901px rgba(0, 0, 0, 0.15);
    border-radius: 8.74534px;
  }
  .text-not {
    font-size: 11px;
    font-weight: 300;
    color: #3d3d3d;
  }
}
.loading {
  animation-name: spin;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transition: all 0.5s ease;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
</style>
<style>

</style>
