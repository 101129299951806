import useJwt from "@/auth/jwt/useJwt";

export default {
    data() {
        return {
            dataLuckyNumber: {
                Phone: ''
            },
            isLoading: false,
            isDisabled: true,
        }
    },
    created() {
        this.getLuckeyNumber();
    },
    watch: {},
    methods: {
        handleGetLuckyNumber() {
            this.getLuckeyNumber();
        },
        hadleByNow(item) {
            this.$emit('hadleByNow', item)
        },
        getLuckeyNumber() {
            this.isLoading = true;
            this.isDisabled = true;
            const number = [
                "0877.753.<span class='spotlight'>268</span>",
                "0877.000.<span class='spotlight'>303</span>",
                "0877.121.<span class='spotlight'>100</span>",
                "0877.911.<span class='spotlight'>144</span>",
                "0877.231.<span class='spotlight'>155</span>",
                "0877.793.<span class='spotlight'>344</span>",
                "0877.536.<span class='spotlight'>390</span>",
                "0877.264.<span class='spotlight'>268</span>",
            ];
            const refreshIntervalId = setInterval(() => {
                this.dataLuckyNumber.PhoneFormated = number[Math.floor(Math.random() * number.length)];
                this.$forceUpdate();
            }, 50);
            useJwt.get("random-sim/10").then(result => {
                if (result && result.data && result.data.result) {
                    setTimeout(() => {
                        clearInterval(refreshIntervalId);
                        this.isLoading = false;
                        this.isDisabled = false;
                        this.dataLuckyNumber = result.data.result;

                    }, 1000);
                }
            }).catch(err => {
                this.isLoading = false;
            })
        },
        f087(value) {
            if (value) {
                return value.substr(0, 4) + " " + value.substr(4);
            }
            return '087 8998595'
        },
    }
}
