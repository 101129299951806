<template>
  <div class="lucky_number mt-3l">
    <div class="lucky_number__item1">
      <h5 class="title" v-if="isHasSearchCode">Số ngẫu nhiên của bạn là</h5>
      <h5 class="title" v-else>Số may mắn được chọn của bạn là</h5>
      <div class="d-flex number-select">
        <span class="phone" v-html="dataLuckyNumber.PhoneFormated"
          >
        </span>
        <button class="rotation-btn" @click="handleGetLuckyNumber">
          <svg
            width="12"
            height="13"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="isLoading ? 'loading' : ''"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.36292 9.96427C1.6835 9.96427 1.95064 10.249 1.95064 10.5338C1.95064 13.8083 4.9427 16.4184 8.5759 16.4184C12.316 16.4184 15.308 13.7608 15.308 10.5338C15.308 7.54395 12.8503 5.12364 9.64449 4.64907V7.63887L5.20984 4.22195L9.64449 0.757578V3.55755C13.5983 3.93721 16.5903 6.92701 16.5903 10.5812C16.5903 14.4727 13.064 17.6049 8.62933 17.6049C4.24811 17.6049 0.668335 14.4727 0.668335 10.5812C0.668335 10.249 0.882053 9.96427 1.36292 9.96427Z"
              fill="#616161"
            />
          </svg>
        </button>
        <span class="text-not">(Chọn số khác)</span>
      </div>
      <hr class="hr" />
    </div>
    <div class="lucky_number__item3 d-flex justify-content-between">
      <div>
        <h5 class="pack_of_data">Gói cước</h5>
        <div class="d-flex number-select">
          <span class="package_information"
            >Gói
            <strong
              >{{ dataLuckyNumber.Pack }}
              <span style="color: #ed1f24;" v-if="!isHasSearchCode">
                {{ dataLuckyNumber.PackPrice | number }}đ
                <span class="b2c-ghtk-fee" style="color: #5e5873; font-weight: 400; font-size: 9px;" v-if="!isHasSearchCode&& dataLuckyNumber.PackPrice !=packPrice && dataLuckyNumber.SimType=== 10">
                ({{ packPrice | number }}đ)
              </span>
              </span>
            </strong>
            <br />
            <span>
              <strong>4GB/ngày</strong> , miễn phí gọi nội mạng Vinaphone &
              iTel</span
            >
            <br />
            <p style="font-style: italic; font-size: 9px;">
              (Ưu đãi giá gói áp dụng cho tháng đầu tiên)
            </p>
          </span>
        </div>
      </div>
      <div class="d-flex flex-column">
        <span class="price_title"  v-if="!isHasSearchCode">Giá tiền</span>
        <span class="price" v-if="!isHasSearchCode"
          >{{
            (dataLuckyNumber.Price + dataLuckyNumber.SimPrice) | number
          }}đ</span
        >
        <span class="price b2c-ghtk-fee b2c-price-texts" v-if="(dataLuckyNumber.Price + dataLuckyNumber.SimPrice) !=50000
        && dataLuckyNumber.SimType=== 10 && !isHasSearchCode" style="font-weight: 400; margin-top: -5px; padding-right: 20px; font-size: 14px; color: #5e5873;">50.000đ</span>
        <button :disabled="isDisabled" class="buy_now"  :class="[isDisabled ? 'disabled' : '',isHasSearchCode?'mt-2':'']" @click="hadleByNow(dataLuckyNumber)">
          {{ isHasSearchCode?'Chọn ngay':'Mua ngay'}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import luckyNumber from "@/mixins/luckyNumber.js";
export default {
  mixins: [luckyNumber],
  props: ['DataPackages'],
  computed:{
    packPrice(){
      if(this.DataPackages.find((m) => m.Name == this.dataLuckyNumber.Pack)){
        return this.DataPackages.find((m) => m.Name == this.dataLuckyNumber.Pack).Price
      }
    },
    isHasSearchCode(){
      return this.$route.query.searchCode
    },
  },
};
</script>
<style scoped lang="scss">
.lucky_number {
  margin: 0 auto;
  margin-top: 32px;
  max-width: 122.4rem;
  border: 28px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 15px 18px;
  &__img {
    position: absolute;
    right: 0;
    top: -75px;
    right: -74px;
  }
  &__item1 {
    text-align: center;
    max-width: 90%;
    margin: 0 auto;
    .title {
      color: #3d3d3d;
      font-size: 14px !important;
      font-weight: 700;
      margin-bottom: 15px;
    }
    .hr {
      width: 100%;
      height: 2.5px;
      background: #e9182c;
      display: inline-block;
    }
  }
  &__item3 {
    // position: absolute;
    // right: 18px;
    // bottom: 15px;
    margin-top: 11px;
    h5 {
      font-size: 13px !important;
      margin-bottom: 5px;
    }
    .price_title {
      text-align: center;
      font-weight: 700;
      font-size: 13px;
      color: #3d3d3d;
      // margin-bottom: 7px;
    }
    .pack_of_data {
      margin-bottom: 10px;
      color: #3d3d3d;
      font-size: 13px;
      color: #3d3d3d;
      font-weight: 700 !important;
    }
    .price {
      text-align: center;
      color: #e9182c;
      font-size: 19px;
      font-weight: 800;
      margin-top: 10px;
      margin-bottom: 9px;
    }
    .buy_now {

      width: 95px;
      height: 28px;
      background: #ed1f24;
      border: 2px solid #ed1f24;
      border-radius: 26.8042px;
      font-weight: 500;
      font-size: 11px;
      color: #ffffff;
    }
    .disablad {
      opacity: 0.8;
    }
    .package_information {
      line-height: 16px;
      font-size: 11px;
      color: #3d3d3d;
      span {
        font-size: 9px !important;
      }
    }
  }
  .number-select {
    gap: 11px;
    align-items: center;
    justify-content: center;
  }
  title {
    font-weight: 700 !important;
    font-size: 17.3333px !important;
    line-height: 14px;
    /* or 81% */
    color: #3d3d3d !important;
  }
  .phone {
    color: #e9182c;
    font-size: 20px;
    font-weight: 700;
    transition: all 0.5s ease;
  }
  .rotation-btn {
    width: 23px;
    height: 23px;
    background: #ffffff;
    border: 1.63975px solid #616161;
    box-shadow: 2.18634px 2.18634px 6.55901px rgba(0, 0, 0, 0.15);
    border-radius: 8.74534px;
    transition: all 0.5s ease;
  }
  .text-not {
    font-size: 10px;
    font-weight: 300;
    color: #3d3d3d;
  }
}
.loading {
  animation-name: spin;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
</style>
