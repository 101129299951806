const getMesErrorFaceMatching = (errorCode) => {
  const errors = {
    1: "Ảnh giấy tờ không tồn tại",
    2: "Hình ảnh là bản photocopy",
    3: "Ảnh giấy tờ tùy thân có dấu hiệu giả mạo",
    4: "Ảnh giấy tờ tùy thân không có mặt",
    5: "Ảnh chân dung không có mặt",
    6: "Ảnh chứa nhiều hơn một mặt người",
    7: "Đeo kính đen",
    8: "Đội mũ",
    9: "Đeo khẩu trang",
    10: "Ảnh chụp từ bức ảnh khác, màn hình thiết bị, bị mờ nhiễu hoặc có dấu hiệu gian lận",
    11: "Mặt người trong ảnh quá nhỏ",
    12: "Mặt người trong ảnh quá gần với lề",
    13: "Ảnh chân dung có kính, mũ",
    99: "Ảnh chụp không hợp lệ",
  };
  return errorCode ? errors[errorCode] : null;
};

const getMesWarningFaceMatching = (warningCode) => {
  const warnings = {
    1: "Giấy tờ tùy thân không nguyên vẹn, Bạn vui lòng kiểm tra lại nhé!",
    2: "Ảnh chụp giấy tờ không hợp lệ do bản photocopy, Bạn vui lòng thực hiện chụp ảnh từ giấy tờ tùy thân gốc nhé!",
    3: "Ảnh chụp giấy tờ tùy thân đã bị lóa, Bạn vui lòng chụp lại nhé!",
    4: "Ảnh giấy tờ tùy thân không có mặt",
    5: "Ảnh chụp giấy tờ không tìm thấy khuôn mặt, Bạn vui lòng kiểm tra và thực hiện lại nhé!",
    6: "Ảnh chân dung có nhiều hơn 1 khuôn mặt, Bạn vui lòng thực hiện lại nhé!",
    7: "Ảnh chân dung không hợp lệ, Bạn vui lòng không đeo kính đen để chụp ảnh nhé!",
    8: "Ảnh chân dung không hợp lệ, Bạn vui lòng không đội mũ để chụp ảnh nhé!",
    9: "Ảnh chân dung không hợp lệ, Bạn vui lòng không đeo khẩu trang để chụp ảnh nhé!",
    10: "Ảnh chụp giấy tờ không hợp lệ do được chụp từ màn hình, thiết bị khác. Bạn vui lòng kiểm tra và thực hiện lại nhé! ",
    11: "Ảnh chân dung không hợp lệ, Bạn vui lòng chụp khuôn mặt gần hơn nhé!",
    12: "Ảnh chân dung không hợp lệ, Bạn vui lòng chụp khuôn mặt xa hơn chút nhé!",
  };
  return warningCode ? warnings[warningCode] : null;
};

const getMesErrorORC = (errorCode) => {
  const errors = {
    1: "Ảnh có dấu hiệu được chụp qua màn hình điện tử, Bạn vui lòng kiểm tra lại nhé!",
    2: "Ảnh giấy tờ tùy thân là bản photocopy",
    3: "Ảnh chụp giấy tờ tùy thân chưa rõ nét thông tin, Bạn vui lòng thực hiện chụp lại nhé!",
    4: "Mã mrzcode trên hộ chiếu bị định dạng sai",
    5: "Giấy tờ tùy thân bị cắt góc",
    6: "Giấy tờ tùy thân bị sát góc",
    7: "Loại thẻ không đúng, có thể sai cả mặt trước và mặt sau",
    99: "Ảnh chụp không hợp lệ ",
  };
  return errorCode ? errors[errorCode] : null;
};

const getMesWarningORC = (warningCode) => {
  const warnings = {
    1: "Ảnh có dấu hiệu được chụp qua màn hình điện tử, Bạn vui lòng kiểm tra lại nhé!",
    2: "Ảnh giấy tờ tùy thân là bản photocopy",
    3: "Ảnh chụp giấy tờ tùy thân chưa rõ nét thông tin, Bạn vui lòng thực hiện chụp lại nhé!",
    4: "Mã mrzcode trên hộ chiếu bị định dạng sai",
    5: "Giấy tờ tùy thân bị cắt góc",
    6: "Giấy tờ tùy thân bị sát góc",
    7: "Loại thẻ không đúng, có thể sai cả mặt trước và mặt sau",
    99: "Ảnh chụp không hợp lệ ",
  };
  return warningCode ? warnings[warningCode] : null;
};

export {
  getMesErrorFaceMatching,
  getMesWarningFaceMatching,
  getMesErrorORC,
  getMesWarningORC,
};
